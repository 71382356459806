/**
 * 路由 主入口
 * @author LiQingSong
 * 路由参数说明：
 * path: '/home'                  链接 -  /home 内部路由 /^(https?:|mailto:|tel:)/.test(path)外链
 * hidden: true                   如果设置为 true ，项目将不会显示在 “导航栏” (默认为 false)
 * redirect: ''                   重定向
 * name:'router-name'             路由名称
 * meta : {
    roles: ['admin','editor']    权限控制页面角色(您可以设置多个角色), 不设置此参数 默认 所有角色都有权限
    title: 'title'               名称显示在侧栏和面包屑、浏览器title(推荐设置)
    icon: 'icon-class'           图标显示在侧栏中
    breadcrumb: [                面包屑内容：1、默认不配置按照路由自动读取；2、false,按照路由自动读取讲不读取此；3、配置对应的面包屑格式如下：
          {
            meta: { title: '标题' },   必填
            linkpath: '/demo',         链接 -  /demo 内部路由 /^(https?:|mailto:|tel:)/.test(path)外链 , 可不写
          }
    ],
    activeMenu: '/example/list'  侧栏选中，如果设置路径，侧栏将突出显示你设置的路径，默认 router.path
    belongTopMenu: '/news'       所属顶级菜单,用于选中顶部菜单，与菜单切换，默认不设置 path.split('/') 第1个；
                                            三级此参数的作用是选中顶级菜单 ，二级此参数的作用是所属某个顶级菜单的下面，两个层级的必须同时填写一致
  }
 */
import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

// 引入Index框架
import LayoutIndex from '@/layout/Index';


/**
 * 固定路由
 * 没有权限要求的路由
 * 所有角色可访问
 */
export const constantRoutes = [
    {
        path: '/login',
        meta: {
            title: '登录'
        },
        component: () => import('@/views/Login'),
        hidden: true
    },
    {
        path: '/loginByPhoneNumber',
        meta: {
            title: '手机号登录'
        },
        component: () => import('@/views/Login/LoginPhone.vue'),
        hidden: true
    },

    {
        path: '/404',
        meta: {
            title: 'Not Found'
        },
        component: () => import('@/views/404'),
        hidden: true
    },
    {
        path: '/work',
        meta: {
            title: '生成日报表'
        },
        component: () => import('@/views/work'),
        hidden: true
    },
    {
        path: '/Claim',
        meta: {
            title: '耗材领用'
        },
        component: () => import('@/views/consumable/Claim.vue'),
        hidden: true
    },
    {
        path: '/statistics',
        component: () => import('@/views/Home/statistics.vue'),
        name: 'statistics',
        meta: {
            title: '统计首页',
            belongTopMenu: '/'
        },
        hidden: true
    },
    {
        path: '/home',
        component: () => import('@/views/Home/dpindex.vue'),
        meta: {
            title: '生产看板'
        }
    },
   // {
   //     path: '/home2',
   //     component: () => import('@/views/Home/index.vue'),
  //      meta: {
    //        title: '生产看板'
   //     }
  //  },
    {
        path: '/device',
        component: () => import('@/views/Home/newIndex.vue'),
        name: 'device',
        meta: {
            title: '机器工作看板',
            belongTopMenu: '/'
        },
        hidden: true
    },
    {
        path: '/personnelOverview',
        component: () => import('@/views/Home/dpSataff.vue'),
        name: 'personnelOverview',
        meta: {
            title: '人员看板',
            belongTopMenu: '/'
        },
        hidden: true
    },
    {
        path: '/orderOverview2',
        component: () => import('@/views/Home/orderIndex.vue'),
        name: 'personnelOverview',
        meta: {
            title: '订单看板',
            belongTopMenu: '/'
        },
        hidden: true
    },
    {
        path: '/orderOverview',
        component: () => import('@/views/Home/dporder.vue'),
        name: 'personnelOverview',
        meta: {
            title: '订单看板',
            belongTopMenu: '/'
        },
        hidden: true
    },
    {
        path: '/',
        redirect: '/home',
        component: LayoutIndex,
        meta: {
            title: '设备数据'
        },
        children: [
            {
                path: 'zkt',
                component: () => import('@/views/Home/index2'),
                name: 'sckb',
                meta: {
                    title: '运行记录',
                    belongTopMenu: '/'
                }
            },
            {
                path: 'index',
                component: () => import('@/views/statistics/index'),
                name: 'statistics',
                meta: {
                    title: '数据分析',
                    belongTopMenu: '/'
                }
            },
            {
                path: 'one',
                component: () => import('@/views/one'),
                name: 'one',
                meta: {
                    title: '详情页',

                    belongTopMenu: '/'
                },
                hidden: true
            }
        ]
    },
    {
        path: '/sc',
        redirect: '/sc',
        component: LayoutIndex,

        meta: {
            title: '生产管理'
        },
        children: [
            {
                path: 'customer',
                component: () => import('@/views/customer'),
                name: 'customer',
                meta: {
                    title: '客户管理',
                    belongTopMenu: '/'
                }
            },
            {
                path: 'companyOrder',
                component: () => import('@/views/companyOrder'),
                name: 'companyOrder',
                meta: {
                    title: '订单管理',
                    belongTopMenu: '/'
                }
            },
            // {
            //     path: 'productionOrder',
            //     component: () => import('@/views/productionOrder'),
            //     name: 'productionOrder',
            //     meta: {
            //         title: '订单管理',
            //
            //         belongTopMenu: '/'
            //     }
            // },
            {
                path: 'companyOrderItem',
                component: () => import('@/views/companyOrderItem'),
                name: 'companyOrderItem',
                hidden: true,
                meta: {
                    title: '子订单管理',
                    belongTopMenu: 'companyOrder'
                }
            },
            {
                path: 'processTemplate',
                component: () => import('@/views/processTemplate'),
                name: 'processTemplate',
                meta: {
                    title: '工序模板管理',
                    belongTopMenu: '/'
                }
            },
            {
                path: 'consumable',
                component: () => import('@/views/consumable'),
                name: 'consumable',
                meta: {
                    title: '耗材管理',
                    belongTopMenu: '/'
                }
            },
            {
                path: 'stockManage',
                component: () => import('@/views/stockManage'),
                name: 'stockManage',
                meta: {
                    title: '库存管理',
                    belongTopMenu: '/'
                }
            },
            // {
            //     path: 'schedule',
            //     component: () => import('@/views/schedule'),
            //     name: 'schedule',
            //     meta: {
            //         title: '生产排程',
            //         belongTopMenu: '/'
            //     }
            // },
            {
                path: 'phoneProductionOrder',
                component: () => import('@/views/phone/org'),
                name: 'phoneProductionOrder',
                hidden: true,
                meta: {
                    title: '手机订单管理',

                    belongTopMenu: '/'
                }
            },
            {
                path: 'phoneSchedule',
                component: () => import('@/views/phone/schedule'),
                name: 'phoneSchedule',
                hidden: true,
                meta: {
                    title: '手机生产排程',

                    belongTopMenu: '/'
                }
            },
            {
                path: 'phoneReport',
                component: () => import('@/views/phone/report'),
                name: 'phoneReport',
                hidden: true,
                meta: {
                    title: '手机设备管理',

                    belongTopMenu: '/'
                }
            },
            {
                path: 'phoneStaffreport',
                component: () => import('@/views/phone/staffreport'),
                name: 'phoneStaffreport',
                hidden: true,
                meta: {
                    title: '手机人员管理',

                    belongTopMenu: '/'
                }
            },
            {
                path: 'phoneconsu',
                component: () => import('@/views/phone/consu'),
                name: 'phoneconsu',
                hidden: true,
                meta: {
                    title: '手机耗材管理',

                    belongTopMenu: '/'
                }
            },
            {
                path: 'lylist',
                component: () => import('@/views/phone/lylist'),
                name: 'lylist',
                hidden: true,
                meta: {
                    title: '手机耗材管理',

                    belongTopMenu: '/'
                }
            }
        ]
    },
    {
        path: '/report',
        redirect: '/report',
        component: LayoutIndex,
        meta: {
            title: '生产报表'
        },
        children: [
            // {
            //     path: 'report',
            //     component: () => import('@/views/report'),
            //     name: 'report',
            //     meta: {
            //         title: '设备报表(扫码)',
            //         belongTopMenu: '/'
            //     }
            // },
            // {
            //     path: 'staffreport',
            //     component: () => import('@/views/report/staff'),
            //     name: 'staffreport',
            //     meta: {
            //         title: '人员报表(扫码)',
            //
            //         belongTopMenu: '/'
            //     }
            // },
            {
                path: 'deviceTask',
                component: () => import('@/views/report/deviceTask'),
                name: 'deviceTask',
                meta: {
                    title: '设备报表(小程序)',
                    belongTopMenu: '/'
                }
            },
            {
                path: 'staffTask',
                component: () => import('@/views/report/staffTask'),
                name: 'staffTask',
                meta: {
                    title: '人员报表(小程序)',
                    belongTopMenu: '/'
                }
            }
        ]
    },
    {
        path: '/xt',
        redirect: '/xt',
        component: LayoutIndex,
        meta: {
            title: '基础信息'
        },
        children: [
            {
                path: 'group',
                component: () => import('@/views/group'),
                name: 'group',
                meta: {
                    title: '部门管理',
                    belongTopMenu: '/'
                }
            },
            {
                path: 'set',
                component: () => import('@/views/set'),
                name: 'set',
                meta: {
                    title: '设备管理',
                    belongTopMenu: '/'
                }
            },
            {
                path: 'staff',
                component: () => import('@/views/staff'),
                name: 'staff',
                meta: {
                    title: '人员管理',
                    belongTopMenu: '/'
                }
            }


        ]
    }

    // {
    //   path: '/member',
    //   redirect: '/member',
    //   component: LayoutIndex,
    //   meta: { title: '会员管理', icon: 'control' },
    //   children: [
    //     {
    //       path: 'member',
    //       component: () => import('@/views/member'),
    //       name: 'home',
    //       meta: { title: '会员列表', icon: 'control', belongTopMenu: '/' }
    //     }
    //   ]
    // }


];

/**
 * 异步路由
 * 需要根据用户角色动态加载的路由
 */
export const asyncRoutes = [


    {
        path: '*',
        redirect: '/404',
        hidden: true
    }
];

/*
// 打印对应链接的权限
const consoleLogRouteRoles = (routes, level) => {
   let obj = {};
   routes.map((item, index) => {
      let { meta, children } = item;
      if (!meta) {
        return item;
      }
      let { title, roles } = meta;
      //console.log(meta);
      obj[title + index + '-' + level ] = roles[0] || '';
      // console.log(children);
      if (children) {
        obj = Object.assign(obj, consoleLogRouteRoles(children, level + 1));
      }
   })
   return obj;
}
console.log(JSON.stringify(consoleLogRouteRoles(asyncRoutes, 1)));
*/

const createRouter = () => new VueRouter({
    // mode: 'history', // 启用的话就是去除#
    scrollBehavior: () => ({
        y: 0
    }),
    base: process.env.BASE_URL,
    routes: constantRoutes
});

const router = createRouter();

// 细节见: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter () {
    const newRouter = createRouter();
    router.matcher = newRouter.matcher; // 重置路由
}

export default router;
