import request from '@/service/lib/request';

/**
 * 用户 - 登录
 * @param {String} url 请求地址
 * @param {Object} data 请求参数
 * @author LiQingSong
 */
export function login (data) {
    return request({
        url: '/auth/store',
        method: 'post',
        data
    });
}

/**
 * 用户手机号- 登录
 * @param {String} url 请求地址
 * @param {Object} data 请求参数
 * @author LiQingSong
 */
export function loginByPhoneNumber (data) {
    return request({
        url: '/auth/loginByPhoneNumber',
        method: 'post',
        data
    });
}


// 修改密码
export function userUpdate (data) {
  console.log('ddd');
  return request({
    url: '/auth/userUpdate',
    method: 'post',
    data
  });
}
/**
 * 用户 - 获取用户信息和权限
 * @author LiQingSong
 */
export function getInfo (token) {
  return request({
    url: '/user/info',
    method: 'get',
    params: { token: token }
  });
}

/**
 * 用户 - 退出
 * @author LiQingSong
 */
export function logout (url) {
  return request({
    url: url,
    method: 'post'
  });
}
