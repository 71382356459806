<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  name: 'App'
};
</script>
<style >
.content {
  padding: 20px;
  box-sizing: border-box;
}
@media screen and (max-width: 750px) {
  .main-conent {
    margin: 10px;
  }
  .elmain {
    padding: 10px;
  }
  .el-dialog {
    width: 90% !important;
  }
  .el-dialog__body {
    padding: 30px 5px;
    color: #606266;
    font-size: 14px;
    word-break: break-all;
  }
}
</style>
