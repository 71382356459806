/**
 * URL工具
 * @author LiQingSong
 */

 /**
 * 获取url中对应参数的值
 * @param  url        链接
 * @param  queryName  参数名
 * @returns String
 * @author LiQingSong
 */
export function getQueryValue(url, queryName) {
    if (url.indexOf("?") === -1) {
        return null;
    }
    let reg = new RegExp("(^|&)" + queryName + "=([^&]*)(&|$)", "i");
    let r = url.split("?")[1].match(reg);
    if ( r != null ){
       return decodeURI(r[2]);
    }else{
       return null;
    }
}

// 定义url字符串拼接的方法
export function setUrlQuery(options) {
    let { url, query } = options;
    if (!url) return '';
    if (query) {
        const queryArr = [];
        for (const key in query) {
            if (query.hasOwnProperty(key)) {
                if (Array.isArray(query[key])) {
                    for (const more in query[key]) {
                        if (query[key][more].hasOwnProperty(more)) {
                            queryArr.push(`${key}=${query[key][more]}`);
                        }
                    }
                } else {
                    queryArr.push(`${key}=${query[key]}`);
                }
            }
        }
        if (url.indexOf('?') !== -1) {
            url = `${url}&${queryArr.join('&')}`;
        } else {
            url = `${url}?${queryArr.join('&')}`;
        }
    }
    return url;
}
