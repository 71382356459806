<template>
  <div class="indexlayout-right-footer">
    <div class="footer-links">
      <!-- <a href="http://jsv1-demo.admin-element-vue.liqingsong.cc" target="_blank">DEMO</a>
            <a href="https://github.com/lqsong/admin-element-vue/tree/javascript.v1" target="_blank">Github</a>
            <a href="https://liqingsong.cc" target="_blank">博客</a>
            <a href="http://www.wyxgn.com" target="_blank">网页小功能</a> -->
    </div>
    <div class="footer-copyright">
      盘工-生产管理平台 - <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank" rel="noopener noreferrer">闽ICP备18016756号-1 </a>
    </div>

  </div>
</template>
<script>
export default {
  name: 'LayoutIndexRightFooter'
};
</script>
<style lang="scss" scoped>
.indexlayout-right-footer {
  // position: fixed;
  // width: calc(100% - 200px);
  bottom: 0;
  padding: 48px 0 24px;
  height: 50px;
  overflow: hidden;
  text-align: center;
  font-size: 14px;
  color: #808695;
  a {
    color: #808695;
  }
  .footer-links {
    margin-bottom: 10px;
    a {
      margin-right: 30px;
      color: #808695;
      text-decoration: none;
      &:last-child {
        margin-right: 0;
      }
      &:hover {
        color: #515a6e;
      }
    }
  }
}
</style>